<template>
  <div>
    <div>
      <b-modal
        id="modal-financial-estimation-show-form-on-lead"
        centered
        :title="`Financial Estimation - ${leadTitle}`"
        hide-footer
        @hidden="hideEstimationModal"
        no-close-on-backdrop
        size="xl"
      >
        <validation-observer ref="financialEstimationValidation">
          <b-form v-on:submit.prevent="financialEstimationValidationForm">
            <b-row>
              <!-- select project type -->
              <b-col md="6" lg="6" xs="12">
                <b-form-group
                  label="Project Type"
                  class="required-label"
                  label-for="projectType"
                >
                  <ValidationProvider
                    name="project type"
                    v-slot="{ errors }"
                    vid="project_type"
                    rules="required"
                  >
                    <v-select
                      id="projectType"
                      placeholder="Choose Project Type"
                      v-model="projectType"
                      :options="projectTypeConstants"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <b-form-group
                  label="Payment Type"
                  class="required-label"
                  label-for="paymentType"
                >
                  <ValidationProvider
                    name="payment type"
                    v-slot="{ errors }"
                    vid="payment_type"
                    rules="required"
                  >
                    <v-select
                      id="paymentType"
                      placeholder="Choose Payment Type"
                      v-model="paymentType"
                      :options="paymentTypeConstants"
                      v-on:input="updateMilestonePaymentType"
                      label="name"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <!-- select currency -->
              <b-col md="12" lg="3">
                <b-form-group
                  label="Currency"
                  class="required-label"
                  label-for="currency"
                >
                  <ValidationProvider
                    name="Currency"
                    v-slot="{ errors }"
                    vid="currency"
                    rules="required"
                  >
                    <v-select
                      id="currency"
                      placeholder="Choose a Currency"
                      v-model="selectCurrency"
                      :options="currencyOption"
                      :reduce="(option) => option?.currency"
                      label="currency"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="12" lg="3">
                <b-form-group
                  label="Final Amount"
                  class="required-label"
                  label-for="amount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="amount"
                    vid="amount"
                    rules="required|min_value:1"
                  >
                    <!--   v-on:keyup="updateMilestoneInfo" -->
                    <b-form-input
                      id="amount"
                      type="text"
                      v-model="amount"
                      v-on:input="loadMileStoneAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Final Amount"
                      class="custom-font"
                    />
                    <template v-if="amountNotMatch">
                      <span class="text-danger custom-font-validation">{{
                        amountNotMatchError
                      }}</span>
                    </template>

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12" lg="2">
                <b-form-group
                  label="VAT"
                  class="required-label"
                  label-for="vatAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vat amount"
                    vid="vat_amount"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="vatAmount"
                      type="text"
                      v-model="vatAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter VAT Amount"
                      class="custom-font"
                      v-on:input="loadMileStoneVat"
                    />
                    <template v-if="vatNotMatch">
                      <span class="text-danger custom-font-validation">{{
                        vatNotMatchError
                      }}</span>
                    </template>
                    <small class="text-danger mt-1">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" lg="2">
                <b-form-group
                  label="TAX"
                  class="required-label"
                  label-for="taxAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="tax amount"
                    vid="tax_amount"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="taxAmount"
                      type="text"
                      v-model="taxAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter TAX Amount"
                      class="custom-font"
                      v-on:keyup="loadMileStoneTax"
                    />
                    <template v-if="taxNotMatch">
                      <span class="text-danger custom-font-validation">{{
                        taxNotMatchError
                      }}</span>
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" lg="2">
                <b-form-group
                  label="Grand Total"
                  class="required-label"
                  label-for="grandAmount"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="grand total"
                    vid="grand_amount"
                    rules="required|min_value:0"
                  >
                    <b-form-input
                      id="grandAmount"
                      type="text"
                      v-model="grandAmount"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Grand Total"
                      class="custom-font"
                      disabled
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6" lg="6" xs="12">
                <b-form-group
                  label="Time Duration (Months)"
                  class="required-label"
                  label-for="timeDuration"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="time duration"
                    vid="time_duration"
                    rules="required|numeric|max_value:12|min_value:1"
                  >
                    <b-form-input
                      id="timeDuration"
                      type="number"
                      v-model="timeDuration"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Time Duration"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <b-form-group
                  label="Number of Milestones"
                  class="required-label"
                  label-for="milestone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="milestone"
                    vid="milestone"
                    rules="required|numeric|max_value:12|min_value:1"
                  >
                    <b-form-input
                      type="number"
                      v-model="milestones"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Number of Milestones"
                      v-on:keyup="updateMilestoneInfo"
                      class="custom-font"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="mt-2">
                <template v-if="isMileStoneLoading">
                  <div class="d-flex justify-content-center mb-1">
                    <b-spinner label="Loading..." /></div
                ></template>
              </b-col>
            </b-row>
            <template v-if="isMileStoneLoading == false">
              <template v-if="milestones <= 12">
                <b-row
                  v-for="(item, index) in +milestones"
                  :key="item"
                  ref="row"
                >
                  <!-- Amount -->
                  <!--  v-on:input="calculateMilestoneTotal" -->
                  <b-col md="2">
                    <b-form-group
                      label="Amount"
                      class="required-label"
                      label-for="amount"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="amount"
                        :vid="'amount' + index"
                        rules="required|min_value:1"
                      >
                        <b-form-input
                          type="text"
                          v-model="milestoneAmmount[index]"
                          placeholder="Per Milestone Amount"
                          class="form-control custom-font"
                          v-on:input="calculatePerMileStoneTotal"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      label="VAT"
                      class="required-label"
                      label-for="vatAmount"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="vat amount"
                        :vid="'vat_amount' + index"
                        rules="required|min_value:0"
                      >
                        <b-form-input
                          type="text"
                          v-model="milestoneVat[index]"
                          placeholder="Per Milestone VAT"
                          class="form-control custom-font"
                          v-on:input="calculatePerMileStoneTotal"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      label="TAX"
                      class="required-label"
                      label-for="tax"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="tax amount"
                        :vid="'tax_amount' + index"
                        rules="required|min_value:0"
                      >
                        <b-form-input
                          type="text"
                          v-model="milestoneTax[index]"
                          placeholder="Per Milestone Amount"
                          class="form-control custom-font"
                          v-on:input="calculatePerMileStoneTotal"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      label="Total"
                      class="required-label"
                      label-for="total"
                    >
                      <ValidationProvider
                        #default="{ errors }"
                        name="total "
                        :vid="'grand_amount' + index"
                        rules="required|min_value:1"
                      >
                        <b-form-input
                          type="text"
                          v-model="milestoneTotal[index]"
                          placeholder="Per Milestone Total"
                          class="form-control custom-font"
                          disabled
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                  <!-- Date -->
                  <b-col md="2">
                    <b-form-group
                      label="Milestone Deadline"
                      class="required-label"
                      label-for="date"
                    >
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="deadline"
                        :vid="'date' + index"
                        rules="required"
                      >
                        <b-form-datepicker
                          v-model="milestoneDeadLine[index]"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Enter Milestone Deadline"
                          class="custom-font"
                          locale="en-US"
                          place
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>

                  <!-- Payment Type -->
                  <b-col md="2">
                    <b-form-group
                      label="Payment Type"
                      class="required-label"
                      label-for="paymentType"
                    >
                      <ValidationProvider
                        name="payment type"
                        :vid="'payment_type' + index"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <v-select
                          id="paymentType"
                          placeholder="Choose Payment Type"
                          v-model="milestonePaymentType[index]"
                          :options="paymentTypeConstants"
                          label="name"
                          class="custom-font"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </ValidationProvider>
                    </b-form-group>
                  </b-col>
                </b-row></template
              >
            </template>

            <!-- loading button -->
            <template v-if="isFinancialEstimationFormLoading">
              <b-button class="float-right" variant="primary" disabled>
                <b-spinner small />
                Loading...
              </b-button>
            </template>

            <!-- submit button -->
            <template v-else>
              <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                :disabled="estimationSubmitDisabled"
              >
                Submit
              </b-button>
            </template>
          </b-form>
        </validation-observer>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip,
} from "bootstrap-vue";
import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_DETAILS_EXPORT,
  LEAD_DETAILS_CREATE,
  LEAD_DETAILS_EDIT,
  LEAD_DETAILS_DELETE,
} from "@/helpers/permissionsConstant";
export default {
  name: "FinancialEstimationForm",

  components: {
    BLink,
    BForm,
    BButton,
    BCard,

    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,

    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    ValidationProvider,
    ValidationObserver,
    BMedia,
    BMediaAside,
    BMediaBody,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: ["selectedLeadInfo", "leadTitle"],
  data() {
    return {
      LEAD_MANAGEMENT_ACCESS,
      LEAD_DETAILS_EXPORT,
      LEAD_DETAILS_CREATE,
      LEAD_DETAILS_EDIT,
      LEAD_DETAILS_DELETE,
      //Custom Validation

      amountNotMatch: false,
      amountNotMatchError: "",
      vatNotMatch: false,
      vatNotMatchError: "",
      taxNotMatch: false,
      taxNotMatchError: "",
      estimationSubmitDisabled: false,
      // Financial Estimation
      selectCurrency: "",
      currencyOption: [],
      projectTypeConstants,
      paymentTypeConstants,
      projectType: "",
      paymentType: "",
      amount: "",
      timeDuration: "",
      vatAmount: "",
      taxAmount: "",
      grandAmount: "",
      milestones: "",
      milestoneAmmount: [],
      milestoneVat: [],
      milestoneTax: [],
      milestoneTotal: [],

      milestoneDeadLine: [],
      milestonePaymentType: [],
      isMileStoneLoading: false,
      isFinancialEstimationFormLoading: false,
    };
  },
  computed: {
    getLeadInfo() {
      return this.selectedLeadInfo;
    },
  },
  async created() {
    try {
      const getCurrencies = await this.getCurrencies();
      this.currencyOption = (getCurrencies?.data?.data || []).map((item) => {
        return {
          currency: item?.currency,
        };
      });
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {
    async getCurrencies() {
      return await this.$api.get("api/currencies/all");
    },
    /* Start Dom Manipulation For Financial Estimation   */
    // to update milestone payment type according milestone payment type
    updateMilestonePaymentType() {
      this.milestonePaymentType = [];
      for (let i = 0; i < this.milestones; i++) {
        this.milestonePaymentType.push(this.paymentType);
      }
    },

    // on change of per milestone input, changing the total of that milestone
    calculatePerMileStoneTotal() {
      this.milestoneTotal = [];
      for (let i = 0; i < this.milestones; i++) {
        const amount = parseFloat(this.milestoneAmmount[i]).toFixed(2);
        const vat = parseFloat(this.milestoneVat[i]).toFixed(2);
        const tax = parseFloat(this.milestoneTax[i]).toFixed(2);
        const total = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);
        this.milestoneTotal.push(total.toFixed(2));
      }
      this.showErrorOnCalculation();
    },
    // on change of final amount, changing only the amount & total of milestone
    loadMileStoneAmount() {
      this.claculateGrandTotal();
      this.milestoneAmmount = [];
      this.milestoneTotal = [];
      for (let i = 0; i < this.milestones; i++) {
        const amount = parseFloat(this.amount / this.milestones).toFixed(2);

        const vat = parseFloat(this.milestoneVat[i]).toFixed(2);
        const tax = parseFloat(this.milestoneTax[i]).toFixed(2);
        const total = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);

        this.milestoneAmmount.push(amount);
        this.milestoneTotal.push(total.toFixed(2));
      }
      if (this.milestones > 0) {
        this.showErrorOnCalculation();
      }
    },
    // on change of main vat , changing only the vat & total of milestone
    loadMileStoneVat() {
      this.claculateGrandTotal();
      this.milestoneVat = [];
      this.milestoneTotal = [];
      for (let i = 0; i < this.milestones; i++) {
        const vat = parseFloat(this.vatAmount / this.milestones).toFixed(2);
        const amount = parseFloat(this.milestoneAmmount[i]).toFixed(2);

        const tax = parseFloat(this.milestoneTax[i]).toFixed(2);
        const total = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);

        this.milestoneVat.push(vat);
        this.milestoneTotal.push(total.toFixed(2));
      }
      if (this.milestones > 0) {
        this.showErrorOnCalculation();
      }
    },
    // on change of main tax , changing only the tax & total of milestone
    loadMileStoneTax() {
      this.claculateGrandTotal();
      this.milestoneTax = [];
      this.milestoneTotal = [];
      for (let i = 0; i < this.milestones; i++) {
        const tax = parseFloat(this.taxAmount / this.milestones).toFixed(2);
        const vat = parseFloat(this.milestoneVat[i]).toFixed(2);
        const amount = parseFloat(this.milestoneAmmount[i]).toFixed(2);

        const total = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);

        this.milestoneTax.push(tax);
        this.milestoneTotal.push(total.toFixed(2));
      }
      if (this.milestones > 0) {
        this.showErrorOnCalculation();
      }
    },
    // on change of final amount, main vat & tax , calculating grand total
    claculateGrandTotal() {
      let mainAmount = parseFloat(this.amount).toFixed(2);
      let mainVat = parseFloat(this.vatAmount).toFixed(2);
      let mainTax = parseFloat(this.taxAmount).toFixed(2);
      let grandTotal =
        parseFloat(mainAmount) + parseFloat(mainVat) + parseFloat(mainTax);

      if (!isNaN(grandTotal)) {
        this.grandAmount =
          parseFloat(mainAmount) + parseFloat(mainVat) + parseFloat(mainTax);
      }
    },
    // on change of milestone number, loading recalculating the milestone
    loadMileStone() {
      this.claculateGrandTotal();
      this.milestoneAmmount = [];
      this.milestonePaymentType = [];
      this.milestoneVat = [];
      this.milestoneTax = [];
      this.milestoneTotal = [];

      for (let i = 0; i < this.milestones; i++) {
        const amount = parseFloat(this.amount / this.milestones).toFixed(2);

        const vat = parseFloat(this.vatAmount / this.milestones).toFixed(2);
        const tax = parseFloat(this.taxAmount / this.milestones).toFixed(2);
        const total = parseFloat(amount) + parseFloat(vat) + parseFloat(tax);

        this.milestoneAmmount.push(amount);
        this.milestoneVat.push(vat);
        this.milestoneTax.push(tax);
        this.milestoneTotal.push(total.toFixed(2));
        this.milestonePaymentType.push(this.paymentType);
      }

      this.isMileStoneLoading = false;
      return true;
    },

    /**
     * matching project amount, main vat & tax with sum of milestone amount, milestone vat & milestone tax
     * showing error and handling submit button if not match & vise versa
     */
    showErrorOnCalculation() {
      let finalAmount = parseFloat(this.amount).toFixed(2);
      let finalVat = parseFloat(this.vatAmount).toFixed(2);
      let finalTax = parseFloat(this.taxAmount).toFixed(2);
      let milestoneAmountTotal = 0;
      let milestoneVatTotal = 0;
      let milestoneTaxTotal = 0;

      for (let i = 0; i < this.milestones; i++) {
        const amount = parseFloat(this.milestoneAmmount[i]).toFixed(2);
        milestoneAmountTotal = milestoneAmountTotal + parseFloat(amount);
        const vat = parseFloat(this.milestoneVat[i]).toFixed(2);
        milestoneVatTotal = milestoneVatTotal + parseFloat(vat);
        const tax = parseFloat(this.milestoneTax[i]).toFixed(2);
        milestoneTaxTotal = milestoneTaxTotal + parseFloat(tax);
      }

      if (milestoneAmountTotal != finalAmount) {
        this.amountNotMatch = true;
        this.amountNotMatchError =
          "Not equal to total milestone amount (Edit Manually) ";
      } else {
        this.amountNotMatch = false;
        this.amountNotMatchError = "";
      }
      if (milestoneVatTotal != finalVat) {
        this.vatNotMatch = true;
        this.vatNotMatchError =
          "Not equal to total milestone VAT (Edit Manually) ";
      } else {
        this.vatNotMatch = false;
        this.vatNotMatchError = "";
      }

      if (milestoneTaxTotal != finalTax) {
        this.taxNotMatch = true;
        this.taxNotMatchError =
          "Not equal to total milestone TAX (Edit Manually) ";
      } else {
        this.taxNotMatch = false;
        this.taxNotMatchError = "";
      }

      if (!this.taxNotMatch && !this.vatNotMatch && !this.amountNotMatch) {
        this.estimationSubmitDisabled = false;
      } else {
        this.estimationSubmitDisabled = true;
      }
    },

    // responsible for the loader on milestone number change
    lazyLoad(fn, ms) {
      return new Promise((resolve) => setTimeout(() => resolve(fn(), ms)));
    },
    // updating milestone all field on change of milestone number
    async updateMilestoneInfo() {
      this.isMileStoneLoading = true;
      await this.lazyLoad(this.loadMileStone, 300);
      this.showErrorOnCalculation();
    },

    hideEstimationModal() {
      this.$bvModal.hide("modal-financial-estimation-show-form-on-lead");
      this.resetEstimationModal();
    },
    resetEstimationModal() {
      //   this.leadId = "";
      this.departmentId = "";
      this.projectType = "";
      this.amount = "";
      this.timeDuration = "";
      this.milestones = "";
      this.paymentType = "";
      this.vatAmount = "";
      this.taxAmount = "";
      this.grandAmount = "";
      this.milestoneAmmount = [];
      this.milestoneVat = [];
      this.milestoneTax = [];
      this.milestoneTotal = [];
      this.milestoneDeadLine = [];
      this.milestonePaymentType = [];
      this.selectCurrency = "";
      // this.currencyOption = [];
      this.amountNotMatch = false;
      this.amountNotMatchError = "";
      this.vatNotMatch = false;
      this.vatNotMatchError = "";
      this.taxNotMatch = false;
      this.taxNotMatchError = "";
    },

    // Financial Estimation Submit
    financialEstimationValidationForm: async function () {
      this.$refs.financialEstimationValidation
        .validate()
        .then(async (success) => {
          if (success) {
            try {
              this.$refs.financialEstimationValidation.reset();

              this.isFinancialEstimationFormLoading = true;

              let finalMilestoneInfo = [];
              let deadlineMismatch = false;
              for (let i = 0; i < this.milestones; i++) {
                if (
                  i != 0 &&
                  this.milestoneDeadLine[i] < this.milestoneDeadLine[i - 1]
                ) {
                  deadlineMismatch = true;
                  break;
                }
              }

              if (deadlineMismatch) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: "Milestone Deadline Not Sequential",
                  },
                });
              } else {
                for (let i = 0; i < this.milestones; i++) {
                  let data = {};
                  data.amount = this.milestoneAmmount[i];
                  data.vat_amount = this.milestoneVat[i];
                  data.tax_amount = this.milestoneTax[i];
                  data.grand_amount = this.milestoneTotal[i];
                  data.date = this.milestoneDeadLine[i];
                  data.payment_type = this.milestonePaymentType[i].value;

                  finalMilestoneInfo.push(data);
                }

                await this.$api.post("/api/projects", {
                  lead_id: this.getLeadInfo?.leadId,
                  department_id: this.getLeadInfo?.departmentId,
                  project_type: this.projectType.value,
                  amount: this.amount,
                  vat_amount: this.vatAmount,
                  tax_amount: this.taxAmount,
                  grand_amount: this.grandAmount,
                  time_duration: this.timeDuration,
                  milestone: this.milestones,
                  status: false,
                  payment_type: this.paymentType.value,
                  milestones: finalMilestoneInfo,
                  currency: this.selectCurrency,
                });

                this.hideEstimationModal();
                this.$emit("loadLeadInfo");
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Success",
                    icon: "BellIcon",
                    variant: "success",
                    text: "Financial Estimation Submitted Successfully ",
                  },
                });
              }
              this.isFinancialEstimationFormLoading = false;
            } catch (error) {
              this.isFinancialEstimationFormLoading = false;
              if (error?.response?.data?.message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              if (error?.response?.data?.errors) {
                this.$refs.financialEstimationValidation.setErrors(
                  error?.response?.data?.errors
                );
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.custom-font-validation {
  font-size: 11px;
}

.required-label label::after {
  content: " *";
  color: red;
}
</style>
