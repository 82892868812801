var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-modal', {
    attrs: {
      "id": "modal-financial-estimation-show-form-on-lead",
      "centered": "",
      "title": "Financial Estimation - ".concat(_vm.leadTitle),
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "xl"
    },
    on: {
      "hidden": _vm.hideEstimationModal
    }
  }, [_c('validation-observer', {
    ref: "financialEstimationValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.financialEstimationValidationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Project Type",
      "label-for": "projectType"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "project type",
      "vid": "project_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "projectType",
            "placeholder": "Choose Project Type",
            "options": _vm.projectTypeConstants,
            "label": "name"
          },
          model: {
            value: _vm.projectType,
            callback: function callback($$v) {
              _vm.projectType = $$v;
            },
            expression: "projectType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Payment Type",
      "label-for": "paymentType"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "payment type",
      "vid": "payment_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "paymentType",
            "placeholder": "Choose Payment Type",
            "options": _vm.paymentTypeConstants,
            "label": "name"
          },
          on: {
            "input": _vm.updateMilestonePaymentType
          },
          model: {
            value: _vm.paymentType,
            callback: function callback($$v) {
              _vm.paymentType = $$v;
            },
            expression: "paymentType"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Currency",
      "label-for": "currency"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Currency",
      "vid": "currency",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "currency",
            "placeholder": "Choose a Currency",
            "options": _vm.currencyOption,
            "reduce": function reduce(option) {
              return option === null || option === void 0 ? void 0 : option.currency;
            },
            "label": "currency"
          },
          model: {
            value: _vm.selectCurrency,
            callback: function callback($$v) {
              _vm.selectCurrency = $$v;
            },
            expression: "selectCurrency"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Final Amount",
      "label-for": "amount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "amount",
      "vid": "amount",
      "rules": "required|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "amount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Final Amount"
          },
          on: {
            "input": _vm.loadMileStoneAmount
          },
          model: {
            value: _vm.amount,
            callback: function callback($$v) {
              _vm.amount = $$v;
            },
            expression: "amount"
          }
        }), _vm.amountNotMatch ? [_c('span', {
          staticClass: "text-danger custom-font-validation"
        }, [_vm._v(_vm._s(_vm.amountNotMatchError))])] : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "VAT",
      "label-for": "vatAmount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "vat amount",
      "vid": "vat_amount",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "vatAmount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter VAT Amount"
          },
          on: {
            "input": _vm.loadMileStoneVat
          },
          model: {
            value: _vm.vatAmount,
            callback: function callback($$v) {
              _vm.vatAmount = $$v;
            },
            expression: "vatAmount"
          }
        }), _vm.vatNotMatch ? [_c('span', {
          staticClass: "text-danger custom-font-validation"
        }, [_vm._v(_vm._s(_vm.vatNotMatchError))])] : _vm._e(), _c('small', {
          staticClass: "text-danger mt-1"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "TAX",
      "label-for": "taxAmount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tax amount",
      "vid": "tax_amount",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "taxAmount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter TAX Amount"
          },
          on: {
            "keyup": _vm.loadMileStoneTax
          },
          model: {
            value: _vm.taxAmount,
            callback: function callback($$v) {
              _vm.taxAmount = $$v;
            },
            expression: "taxAmount"
          }
        }), _vm.taxNotMatch ? [_c('span', {
          staticClass: "text-danger custom-font-validation"
        }, [_vm._v(_vm._s(_vm.taxNotMatchError))])] : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "lg": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Grand Total",
      "label-for": "grandAmount"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "grand total",
      "vid": "grand_amount",
      "rules": "required|min_value:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "grandAmount",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Grand Total",
            "disabled": ""
          },
          model: {
            value: _vm.grandAmount,
            callback: function callback($$v) {
              _vm.grandAmount = $$v;
            },
            expression: "grandAmount"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Time Duration (Months)",
      "label-for": "timeDuration"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "time duration",
      "vid": "time_duration",
      "rules": "required|numeric|max_value:12|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "timeDuration",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Time Duration"
          },
          model: {
            value: _vm.timeDuration,
            callback: function callback($$v) {
              _vm.timeDuration = $$v;
            },
            expression: "timeDuration"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "lg": "6",
      "xs": "12"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Number of Milestones",
      "label-for": "milestone"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "milestone",
      "vid": "milestone",
      "rules": "required|numeric|max_value:12|min_value:1"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Enter Number of Milestones"
          },
          on: {
            "keyup": _vm.updateMilestoneInfo
          },
          model: {
            value: _vm.milestones,
            callback: function callback($$v) {
              _vm.milestones = $$v;
            },
            expression: "milestones"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "mt-2",
    attrs: {
      "md": "12"
    }
  }, [_vm.isMileStoneLoading ? [_c('div', {
    staticClass: "d-flex justify-content-center mb-1"
  }, [_c('b-spinner', {
    attrs: {
      "label": "Loading..."
    }
  })], 1)] : _vm._e()], 2)], 1), _vm.isMileStoneLoading == false ? [_vm.milestones <= 12 ? _vm._l(+_vm.milestones, function (item, index) {
    return _c('b-row', {
      key: item,
      ref: "row",
      refInFor: true
    }, [_c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "Amount",
        "label-for": "amount"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "amount",
        "vid": 'amount' + index,
        "rules": "required|min_value:1"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref10) {
          var errors = _ref10.errors;
          return [_c('b-form-input', {
            staticClass: "form-control custom-font",
            attrs: {
              "type": "text",
              "placeholder": "Per Milestone Amount"
            },
            on: {
              "input": _vm.calculatePerMileStoneTotal
            },
            model: {
              value: _vm.milestoneAmmount[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestoneAmmount, index, $$v);
              },
              expression: "milestoneAmmount[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "VAT",
        "label-for": "vatAmount"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "vat amount",
        "vid": 'vat_amount' + index,
        "rules": "required|min_value:0"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref11) {
          var errors = _ref11.errors;
          return [_c('b-form-input', {
            staticClass: "form-control custom-font",
            attrs: {
              "type": "text",
              "placeholder": "Per Milestone VAT"
            },
            on: {
              "input": _vm.calculatePerMileStoneTotal
            },
            model: {
              value: _vm.milestoneVat[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestoneVat, index, $$v);
              },
              expression: "milestoneVat[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "TAX",
        "label-for": "tax"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "tax amount",
        "vid": 'tax_amount' + index,
        "rules": "required|min_value:0"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref12) {
          var errors = _ref12.errors;
          return [_c('b-form-input', {
            staticClass: "form-control custom-font",
            attrs: {
              "type": "text",
              "placeholder": "Per Milestone Amount"
            },
            on: {
              "input": _vm.calculatePerMileStoneTotal
            },
            model: {
              value: _vm.milestoneTax[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestoneTax, index, $$v);
              },
              expression: "milestoneTax[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "Total",
        "label-for": "total"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "total ",
        "vid": 'grand_amount' + index,
        "rules": "required|min_value:1"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref13) {
          var errors = _ref13.errors;
          return [_c('b-form-input', {
            staticClass: "form-control custom-font",
            attrs: {
              "type": "text",
              "placeholder": "Per Milestone Total",
              "disabled": ""
            },
            model: {
              value: _vm.milestoneTotal[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestoneTotal, index, $$v);
              },
              expression: "milestoneTotal[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "Milestone Deadline",
        "label-for": "date"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "deadline",
        "vid": 'date' + index,
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref14) {
          var errors = _ref14.errors;
          return [_c('b-form-datepicker', {
            staticClass: "custom-font",
            attrs: {
              "state": errors.length > 0 ? false : null,
              "placeholder": "Enter Milestone Deadline",
              "locale": "en-US",
              "place": ""
            },
            model: {
              value: _vm.milestoneDeadLine[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestoneDeadLine, index, $$v);
              },
              expression: "milestoneDeadLine[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1), _c('b-col', {
      attrs: {
        "md": "2"
      }
    }, [_c('b-form-group', {
      staticClass: "required-label",
      attrs: {
        "label": "Payment Type",
        "label-for": "paymentType"
      }
    }, [_c('ValidationProvider', {
      attrs: {
        "name": "payment type",
        "vid": 'payment_type' + index,
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref15) {
          var errors = _ref15.errors;
          return [_c('v-select', {
            staticClass: "custom-font",
            attrs: {
              "id": "paymentType",
              "placeholder": "Choose Payment Type",
              "options": _vm.paymentTypeConstants,
              "label": "name"
            },
            model: {
              value: _vm.milestonePaymentType[index],
              callback: function callback($$v) {
                _vm.$set(_vm.milestonePaymentType, index, $$v);
              },
              expression: "milestonePaymentType[index]"
            }
          }), _c('small', {
            staticClass: "text-danger"
          }, [_vm._v(_vm._s(errors[0]))])];
        }
      }], null, true)
    })], 1)], 1)], 1);
  }) : _vm._e()] : _vm._e(), _vm.isFinancialEstimationFormLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.estimationSubmitDisabled
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }